import { Layout } from "../../layouts";
import { Title, Section, Button, FormInput } from "@lleed-partners/factor";
import { Download32 } from "@carbon/icons-react";
import { Icon } from "../../fragments/Icon";
import { StaticImage, getImage, getSrc } from "gatsby-plugin-image";
import { asBackground } from "../../fragments/styles";
import { I18nextContext, useI18next, Link } from "gatsby-plugin-react-i18next";
import { Helmet } from "gatsby-plugin-react-i18next";
import { useContext } from "react";
import { graphql } from "gatsby";

const sheetStyles = {
  borderRadius: "5px",
  overflow: "hidden",
  boxShadow: "0 5px 15px rgba(0, 0, 0, .1), 0 1px 2px 1px rgba(0, 0, 0, .1)",
};

export default ({ data }) => {
  const { t } = useI18next();

  const { language } = useContext(I18nextContext);

  return (
    <Layout>
      <Helmet>
        <meta
          name="description"
          content={t(
            "Leverage over-the-counter FFA market data to improve your trading strategies."
          )}
        />
        <meta
          property="og:image"
          content={getSrc(data.featuredImage.childImageSharp.gatsbyImageData)}
        />
        <title>{t("FFA Intraday Data")}</title>
      </Helmet>
      <Section
        style={{
          paddingTop: "15rem",
        }}
        fullScreen
        intent="ghostInverted"
        backgroundMedia={
          <StaticImage
            src="../../images/content/marketing/ffa.jpg"
            alt=""
            style={asBackground}
            loading="eager"
          />
        }
        title={
          <Title
            inline={false}
            title={t("FFA Intraday Data:")}
            subtitle={t("parsing unstructured data for live insights")}
          >
            {t(
              "Leverage over-the-counter FFA market data to improve your trading strategies."
            )}
          </Title>
        }
      ></Section>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "2rem",
            flex: "1 0 20rem",
          }}
        >
          <div
            style={{
              maxWidth: "25rem",
              margin: "auto",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "0",
                  right: "0",
                  paddingBottom: "4rem",
                  paddingRight: "4rem",
                }}
              >
                <StaticImage
                  style={sheetStyles}
                  src="../../images/content/marketing/brochure-thumbnails/ffa-data-pager-2.png"
                  alt="Brochure"
                />
              </div>
              <div
                style={{
                  paddingTop: "4rem",
                  paddingLeft: "4rem",
                }}
              >
                <StaticImage
                  style={sheetStyles}
                  src="../../images/content/marketing/brochure-thumbnails/ffa-data-pager.png"
                  alt="Brochure"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "2rem",
            flex: "1 0 20rem",
          }}
        >
          <Title
            inline={false}
            title={t("Elevate your FFA intraday data")}
            cta={
              <div style={{ maxWidth: "25rem" }}>
                <form
                  method="post"
                  netlify-honeypot="bot-field"
                  data-netlify="true"
                  name="ffa-data"
                  action={"/" + language + "/brochures/ffa-data-download/"}
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="ffa-data" />
                  <FormInput
                    type="email"
                    label={t("Enter your work email")}
                    placeholder="john.doe@company.com"
                    name="email"
                    required={true}
                  />
                  <div style={{ display: "flex" }}>
                    <Button
                      intent="primary"
                      rightIcon={<Icon icon={<Download32 />} />}
                      as="button"
                      type="submit"
                    >
                      Download
                    </Button>
                    <p style={{ paddingLeft: "1rem" }}>
                      or{" "}
                      <Link to="/brochures/ffa-data-download/">download</Link>{" "}
                      without registering.
                    </p>
                  </div>
                </form>
              </div>
            }
          >
            {t(
              "Get a free sample of our FFA intraday data as CSV and see how it can help you make better trading decisions. The download also includes a short brochure with more information about the data."
            )}
          </Title>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    featuredImage: file(
      absolutePath: { glob: "**/images/content/marketing/ffa.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
